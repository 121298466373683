import { useEffect, useState, useMemo } from 'react';
import { useMeeting } from '../meeting/meetingProviderContextDef';
/**
 *
 * @param {string} connectionId
 * @param {{onMeeting:{
 *  onParticipantJoined?: Function,
 *  onParticipantLeft?: Function,
 *  onChatMessage?: Function
 * }}}
 * @returns {{
 *  connection: {
 *    id: string;
 *    close: Function;
 *    payload: string;
 *    meeting: {
 *      id: string;
 *      sendChatMessage: Function;
 *      end: Function;
 *      participants: Array<ConnectionParticipant>;
 *      };
 *  }}}
 */
const useConnection = (
  connectionId,
  { onMeeting } = {
    onMeeting: {
      onParticipantJoined: () => {},
      onParticipantLeft: () => {},
      onChatMessage: () => {}
    }
  }
) => {
  const onParticipantJoined = onMeeting?.onParticipantJoined;
  const onParticipantLeft = onMeeting?.onParticipantLeft;
  const onChatMessage = onMeeting?.onChatMessage;

  const meeting = useMeeting();

  const { connection, connectionParticipants } = useMemo(() => {
    const connection = meeting?.connections.get(connectionId);

    if (!connection) {
      throw new Error('connectionId not found');
    }

    const connectionParticipants = new Map(connection.meeting.participants);

    return {
      connection,
      connectionParticipants
    };
  }, [meeting]);

  const [_connectionParticipants, setConnectionParticipants] = useState(
    new Map(connectionParticipants)
  );

  const _handleParticipantJoined = participant => {
    setConnectionParticipants(s => {
      s.set(participant.id, participant);
      const participantsToSet = new Map(s);
      return participantsToSet;
    });
    onParticipantJoined(participant);
  };
  const _handleParticipantLeft = participantId => {
    setConnectionParticipants(s => {
      s.delete(participantId);
      const newParticipants = new Map(s);
      return newParticipants;
    });
    onParticipantLeft(participantId);
  };

  const _handleChatMessage = data => {
    onChatMessage(data);
  };

  useEffect(() => {
    connection?.meeting.on('participant-joined', _handleParticipantJoined);
    connection?.meeting.on('participant-left', _handleParticipantLeft);
    connection?.meeting.on('chat-message', _handleChatMessage);

    return () => {
      connection?.meeting.off('participant-joined', _handleParticipantJoined);
      connection?.meeting.off('participant-left', _handleParticipantLeft);
      connection?.meeting.off('chat-message', _handleChatMessage);
    };
  }, [connection]);

  return {
    connection: {
      id: connection.id,
      close: connection.close,
      payload: connection.payload,
      meeting: {
        id: connection.meeting.id,
        sendChatMessage: connection.meeting.sendChatMessage,
        end: connection.meeting.end,
        participants: _connectionParticipants
      }
    }
  };
};

export default useConnection;
